import moment from 'moment';
import qs from 'qs';

import { LocationIndexRecord } from '@headway/api/models/LocationIndexRecord';
import { MessageThreadProviderNested } from '@headway/api/models/MessageThreadProviderNested';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderSearchRead } from '@headway/api/models/ProviderSearchRead';
import { ProviderStateSearchIndexRecordRead } from '@headway/api/models/ProviderStateSearchIndexRecordRead';
import { ProviderSummary } from '@headway/api/models/ProviderSummary';

/**
 * NOTE: if providerAddresses is undefined, this returns true
 * (which is quite misleading and should probably be changed)
 */
export const isProviderTelehealthOnly = (
  providerAddresses?: Array<ProviderAddressRead | LocationIndexRecord>
): boolean =>
  !(providerAddresses && providerAddresses.some((address) => address.isActive));

export const providerSupportsTelehealth = (
  provider: ProviderRead | ProviderStateSearchIndexRecordRead
): boolean => {
  return (
    // condition for ProviderRead
    ('providerLicenseState' in provider &&
      provider.providerLicenseState.telehealthAvailabilityCount &&
      provider.providerLicenseState.telehealthAvailabilityCount > 0) ||
    // condition for ProviderStateSearchIndexRecordRead
    ('searchProviderLicenseState' in provider &&
      provider.searchProviderLicenseState.telehealthAvailabilityCount &&
      provider.searchProviderLicenseState.telehealthAvailabilityCount > 0) ||
    false
  );
};

export interface ProviderBioFields {
  bioAboutYou: string | undefined;
  bioTherapyApproach: string | undefined;
  bioTakeAways: string | undefined;
}

/**
 * Determines if a provider has completed enough of their Q/A to begin
 * using it in place of their bio/highlights.  Criteria is that 2 out
 * of the 3 questions should be answered.
 */
export const hasQualifiedForNewProfile = (bio: ProviderBioFields) => {
  const completed = Object.values(bio).filter(Boolean);

  return completed.length >= 2;
};

/**
 * Determines if the given provider object is returned from the Algolia providers_states index.
 * This util function can be used to help with type narrowing.
 *
 */
export const isAlgoliaProviderStateRecord = (
  provider:
    | ProviderRead
    | ProviderSummary
    | ProviderSearchRead
    | ProviderStateSearchIndexRecordRead
    | undefined
): provider is ProviderStateSearchIndexRecordRead =>
  provider !== undefined && 'objectID' in provider && 'providerId' in provider;

/**
 * Returns the provider ID from any kind of provider object (including from
 * Algolia) as a number.
 */
export const getProviderId = (
  provider:
    | ProviderRead
    | ProviderSearchRead
    | ProviderStateSearchIndexRecordRead
): number => {
  if ('providerId' in provider && provider?.providerId) {
    return provider.providerId;
  }
  if ('objectID' in provider) {
    return Number(provider.objectID);
  }
  return provider.id;
};

export const PROVIDER_BIO_ABOUT_YOU_PROMPT =
  'What should your client know about you?';
export const PROVIDER_BIO_THERAPY_APPROACH_PROMPT_FOR_NON_PRESCRIBER =
  'What is your approach to therapy?';
export const PROVIDER_BIO_THERAPY_APPROACH_PROMPT_FOR_PRESCRIBER =
  'What is your approach to providing care?';
export const PROVIDER_BIO_TAKE_AWAYS_PROMPT =
  'What can clients expect to take away from sessions with you?';
export const PROVIDER_BIO_INPUT_LIMIT = 2000;

export const getBioTherapyApproachHeader = (isProviderPrescriber?: boolean) => {
  return isProviderPrescriber
    ? PROVIDER_BIO_THERAPY_APPROACH_PROMPT_FOR_PRESCRIBER
    : PROVIDER_BIO_THERAPY_APPROACH_PROMPT_FOR_NON_PRESCRIBER;
};

export const getFormattedEducation = (
  provider: ProviderRead
): string | undefined => {
  if (provider.degreeType && provider.school) {
    return `${provider.degreeType} at ${provider.school}`;
  }
  return provider.degreeType || provider.school || undefined;
};

type ProviderProfilePathQueryParams = {
  preferredCarrierId?: number;
  isBlueCard?: boolean;
  sessionId?: number;
  state?: string;
  queryId?: string;
};
export const getProviderProfilePath = (
  slug: string,
  {
    preferredCarrierId,
    isBlueCard,
    sessionId,
    state,
    queryId,
  }: ProviderProfilePathQueryParams = {}
) => {
  const queryStringParams: ProviderProfilePathQueryParams = {};
  if (typeof preferredCarrierId === 'number') {
    queryStringParams.preferredCarrierId = preferredCarrierId;
  }
  if (typeof isBlueCard === 'boolean' && !!isBlueCard) {
    queryStringParams.isBlueCard = isBlueCard;
  }
  if (typeof sessionId === 'number' && !!sessionId) {
    queryStringParams.sessionId = sessionId;
  }
  if (typeof state === 'string' && !!state) {
    queryStringParams.state = state;
  }
  if (!!queryId) {
    queryStringParams.queryId = queryId;
  }
  return `/providers/${slug}${
    Object.keys(queryStringParams).length
      ? qs.stringify(queryStringParams, { addQueryPrefix: true })
      : ''
  }`;
};

// NOTE: provider.displayName *IS NOT THE SAME* as provider.displayFirstName + displayLastName
export const getProviderDisplayFirstAndLastWithPrenomial = (providerNameData: {
  displayFirstName?: string;
  displayLastName?: string;
  prenomial?: string;
}): string => {
  return `${
    providerNameData.prenomial ? `${providerNameData.prenomial} ` : ''
  }${getProviderDisplayFirstAndLast(providerNameData)}`;
};

export const getProviderDisplayFirstAndLast = ({
  displayFirstName,
  displayLastName,
}: {
  displayFirstName?: string;
  displayLastName?: string;
}): string => {
  return `${displayFirstName} ${displayLastName}`;
};

export const formatProviderPronouns = (
  provider?:
    | ProviderRead
    | ProviderSearchRead
    | ProviderStateSearchIndexRecordRead
    | MessageThreadProviderNested,
  options?: {
    prependSpace?: boolean;
  }
): string => {
  if (!provider) {
    return '';
  }
  if (provider.pronouns) {
    return `${options?.prependSpace ? ' ' : ''}(${provider.pronouns})`;
  }
  return '';
};

export const formatProviderInitials = ({
  displayFirstName,
  displayLastName,
}:
  | ProviderRead
  | ProviderSearchRead
  | ProviderStateSearchIndexRecordRead
  | MessageThreadProviderNested): string => {
  return `${displayFirstName?.charAt(0) || ''}${
    displayLastName?.charAt(0) || ''
  }`;
};

export const getProviderActiveAndLiveStates = (provider: ProviderRead) => {
  return provider.activeProviderLicenseStates.filter(
    (state) => state.liveOn !== null && moment() > moment(state.liveOn)
  );
};

export const getProviderBookableStates = (provider: ProviderRead) => {
  const pfecs = provider.providerFrontEndCarriers;
  return getProviderActiveAndLiveStates(provider)
    .filter((pls) => {
      return pfecs?.some(
        (pfec) =>
          pfec.providerLicenseStateId === pls.id &&
          pfec.appointmentReadyDate &&
          new Date(pfec.appointmentReadyDate) <= new Date()
      );
    })
    .map((pls) => pls.state);
};
