/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum UserFreezeReason {
    COORDINATION_OF_BENEFITS_DEPRECATED = 'COORDINATION_OF_BENEFITS',
    AWAITING_COB_USER_ACTION = 'AWAITING_COB_USER_ACTION',
    AWAITING_AUTOPAY_CX_ACTION = 'AWAITING_AUTOPAY_CX_ACTION',
    COB_NEEDS_PAYER_CONFIRMATION = 'COB_NEEDS_PAYER_CONFIRMATION',
    TERMED_PLAN = 'TERMED_PLAN',
    OON_PLAN = 'OON_PLAN',
    PREMIUM_PAYMENT = 'PREMIUM_PAYMENT',
    REFERRAL_OR_PREAUTH_REQUIRED = 'REFERRAL_OR_PREAUTH_REQUIRED',
    OTHER = 'OTHER',
    PATIENT_ELIGIBILITY_NOT_FOUND = 'PATIENT_ELIGIBILITY_NOT_FOUND',
    WAIVED_SESSION_MAX_HIT = 'WAIVED_SESSION_MAX_HIT'
}


export const UserFreezeReasonMetadata: EnumMetadata<UserFreezeReason> = {
    name: 'UserFreezeReason',
    values: {
            COORDINATION_OF_BENEFITS_DEPRECATED: 'COORDINATION_OF_BENEFITS',
            AWAITING_COB_USER_ACTION: 'AWAITING_COB_USER_ACTION',
            AWAITING_AUTOPAY_CX_ACTION: 'AWAITING_AUTOPAY_CX_ACTION',
            COB_NEEDS_PAYER_CONFIRMATION: 'COB_NEEDS_PAYER_CONFIRMATION',
            TERMED_PLAN: 'TERMED_PLAN',
            OON_PLAN: 'OON_PLAN',
            PREMIUM_PAYMENT: 'PREMIUM_PAYMENT',
            REFERRAL_OR_PREAUTH_REQUIRED: 'REFERRAL_OR_PREAUTH_REQUIRED',
            OTHER: 'OTHER',
            PATIENT_ELIGIBILITY_NOT_FOUND: 'PATIENT_ELIGIBILITY_NOT_FOUND',
            WAIVED_SESSION_MAX_HIT: 'WAIVED_SESSION_MAX_HIT'
    }
}
