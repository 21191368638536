import { css } from '@emotion/react';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Box, Divider, Skeleton, useMediaQuery } from '@mui/material';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';

import { AvailabilityEventResponse } from '@headway/api/models/AvailabilityEventResponse';
import { FrontEndCarrierNested } from '@headway/api/models/FrontEndCarrierNested';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { ProviderSearchRead } from '@headway/api/models/ProviderSearchRead';
import { ProviderStateSearchIndexRecordRead } from '@headway/api/models/ProviderStateSearchIndexRecordRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import {
  AcceptsInsurance as AcceptsInsuranceIcon,
  InPerson as InPersonIcon,
  Specialties as SpecialtiesIcon,
  VideoChat as VideoChatIcon,
} from '@headway/icons/dist/provider';
import {
  carriersWithSubsidiaries,
  convertCarrierIdstoPatientSearchableCarrierIds,
} from '@headway/shared/utils/carriers';
import { transformCloudflareImg } from '@headway/shared/utils/cloudflareImage';
import { isFrontEndCarrierIdBcbs } from '@headway/shared/utils/insuranceUtils';
import {
  formatProviderPronouns,
  getProviderProfilePath,
  isAlgoliaProviderStateRecord,
  isProviderTelehealthOnly,
  providerSupportsTelehealth,
} from '@headway/shared/utils/providers';
import {
  getStyleTagProperties,
  StyleTagProperties,
} from '@headway/shared/utils/providerStyleTag';

import { theme } from '../theme';
import { Tooltip } from '../Tooltip';

type ImageWithPlaceholderProps = {
  src: string | undefined;
  className?: string;
};

export const ImageWithPlaceholder = ({
  src,
  className,
}: ImageWithPlaceholderProps) => {
  const [loaded, setLoaded] = React.useState(false);
  const onLoad = () => {
    setLoaded(true);
  };
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [isComplete, setIsComplete] = React.useState(false);

  const imageReady = loaded || isComplete;
  const showImage = src && imageReady;

  useEffect(() => {
    if (imgRef.current) {
      setIsComplete(imgRef.current.complete);
    }
  }, [src]);

  return (
    <div className={className}>
      <Avatar
        className={className}
        css={{
          display: showImage ? 'none' : 'flex',
          backgroundColor: theme.color.lightGray,
          [theme.media.smallDown]: {
            paddingLeft: theme.space.base,
          },
        }}
      >
        <PersonIcon className={className} css={{ marginTop: '0 !important' }} />
      </Avatar>
      <img
        className={className}
        ref={imgRef}
        css={{
          opacity: showImage ? 1 : 0,
          transition: 'opacity 400ms ease',
          '-webkit-transition': 'opacity 400ms ease',
          '-moz-transition': 'opacity 400ms ease',
          '-ms-transition': 'opacity 400ms ease',
          '-o-transition': 'opacity 400ms ease',
          objectFit: 'cover',
          objectPosition: 'center',
        }}
        src={src}
        onLoad={onLoad}
      />
    </div>
  );
};

type ProviderCardProps = {
  provider: ProviderSearchRead | ProviderStateSearchIndexRecordRead;
  isBlueCard?: boolean;
  highlightCount: number;
  frontEndCarrierId?: number;
  frontEndCarrierName?: string;
  insuranceLogo?: React.ReactNode;
  isPreview?: boolean;
  providerAvailabilities?: AvailabilityEventResponse[];
  frontendCarriers?: FrontEndCarrierRead[] | null;
  searchedState?: UnitedStates;
  specialties?: React.ReactNode[];
  sessionId?: number; // TODO this should be required after algolia is removed
  algoliaQueryId?: string;
} & React.DOMAttributes<HTMLDivElement>;

export const ProviderCard = React.forwardRef<HTMLDivElement, ProviderCardProps>(
  (
    {
      provider,
      highlightCount,
      frontEndCarrierId,
      frontEndCarrierName,
      insuranceLogo,
      isPreview = false,
      isBlueCard = false,
      providerAvailabilities = [],
      frontendCarriers,
      searchedState,
      specialties,
      sessionId,
      onClick,
      algoliaQueryId,
      ...rest
    }: ProviderCardProps,
    ref
  ) => {
    return (
      <CompassAdvancedSearchProviderCard
        isPreview={isPreview}
        isBlueCard={isBlueCard}
        provider={provider}
        providerAvailabilities={providerAvailabilities}
        ref={ref}
        frontendCarriers={frontendCarriers}
        specialties={specialties}
        frontEndCarrierId={frontEndCarrierId}
        frontEndCarrierName={frontEndCarrierName}
        onClick={onClick}
        sessionId={sessionId}
        searchedState={searchedState}
        algoliaQueryId={algoliaQueryId}
      />
    );
  }
);

interface CompassAdvancedSearchProviderCardProps {
  isPreview: boolean;
  isBlueCard: boolean;
  provider: ProviderSearchRead | ProviderStateSearchIndexRecordRead;
  ref: any;
  frontendCarriers?: FrontEndCarrierRead[] | null;
  specialties?: React.ReactNode[];
  frontEndCarrierId?: number;
  frontEndCarrierName?: string;
  providerAvailabilities?: AvailabilityEventResponse[];
  sessionId?: number;
  searchedState?: UnitedStates;
  onClick?: any;
  algoliaQueryId?: string;
}

interface TagsSectionProps {
  tags: StyleTagProperties[];
  className?: string;
}

const TagsSection = ({ tags, className }: TagsSectionProps) => {
  return (
    <div css={tagsContainerCss} className={className || undefined}>
      {tags.map((tag: StyleTagProperties) => (
        <div css={tagCss} key={tag.name}>
          {tag.IconComponent ? <tag.IconComponent /> : null}
          <span css={tagNameCss}>{tag.name}</span>
        </div>
      ))}
    </div>
  );
};

interface BioSectionProps {
  bio: string;
  className?: string;
  lineLimit?: number;
}

const BioSection = ({ bio, className }: BioSectionProps) => {
  let strippedString = bio.replace(/(<([^>]+)>)/gi, '');

  return (
    <div css={bioCss} className={className || undefined}>
      <span className="line-clamp-2">{`"${strippedString}"`}</span>
    </div>
  );
};

interface NextAvailableSectionProps {
  providerAvailabilities: AvailabilityEventResponse[];
  isMobile: boolean;
  className?: string;
}

const NextAvailableSection = ({
  providerAvailabilities,
  isMobile,
  className,
}: NextAvailableSectionProps) => {
  const nextAvailability = providerAvailabilities[0];
  const nextStartDateMoment = moment(nextAvailability.startDate);
  return (
    <div
      css={{
        marginTop: theme.space.base,
        fontSize: theme.fontSize.sm,
        display: 'flex',
        alignItems: 'center',
      }}
      className={className}
    >
      <div
        css={{
          color: theme.color.black,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span>Next Available:</span>
        <div css={{ marginLeft: theme.space.sm }}>
          {`${nextStartDateMoment.format(
            'MMM Do'
          )}, ${nextStartDateMoment.format('h:mma')}`}
        </div>
      </div>
    </div>
  );
};

// helper function to make sure the provider has a BCBS provider when we're in BlueCard state
const providerHasBCBSCarrier = (frontEndCarriersIds: number[] = []) =>
  frontEndCarriersIds.some(isFrontEndCarrierIdBcbs);

const CompassAdvancedSearchProviderCard = ({
  isPreview,
  isBlueCard,
  provider,
  providerAvailabilities,
  ref,
  frontendCarriers,
  specialties = [],
  frontEndCarrierId,
  frontEndCarrierName,
  sessionId,
  searchedState,
  algoliaQueryId,
  onClick,
}: CompassAdvancedSearchProviderCardProps) => {
  const Container = isPreview ? 'div' : 'a';
  const addresses = isAlgoliaProviderStateRecord(provider)
    ? provider.searchProviderLicenseState.locations
    : provider.providerAddresses;

  let providerInsurances: (string | undefined)[] = [];
  let providerCarriersWithSubsidiaries: FrontEndCarrierNested[] = [];
  const frontEndCarrierIdsForProvider = isAlgoliaProviderStateRecord(provider)
    ? provider.searchProviderLicenseState.frontEndCarrierIds
    : undefined;
  const searchableFrontEndCarrierIdsForProvider = frontEndCarrierIdsForProvider
    ? convertCarrierIdstoPatientSearchableCarrierIds(
        frontEndCarrierIdsForProvider
      )
    : undefined;
  if (
    frontendCarriers &&
    frontendCarriers.length &&
    searchableFrontEndCarrierIdsForProvider?.length
  ) {
    providerInsurances = frontendCarriers
      .filter((c) => searchableFrontEndCarrierIdsForProvider.includes(c.id))
      .map((c) => c.name);

    const carriersById = keyBy(frontendCarriers, 'id');
    const carriersByParent = groupBy(
      frontendCarriers,
      (c) => c.eligibilityEffectiveCarrierId
    );
    const providerCarriers = searchableFrontEndCarrierIdsForProvider.map(
      (carrierId) => carriersById[carrierId]
    );
    providerCarriersWithSubsidiaries = carriersWithSubsidiaries(
      providerCarriers,
      carriersByParent
    );
  }

  const acceptsSelectedInsurance =
    frontEndCarrierId &&
    providerCarriersWithSubsidiaries
      .map((c) => c.id)
      .indexOf(frontEndCarrierId) > -1;

  const isInsuranceBCBS = isFrontEndCarrierIdBcbs(frontEndCarrierId || 0);

  // if the provider is INN with the current user's insurance, just show that carrier
  // otherwise, show all the carriers the provider is INN with
  const insuranceText = searchableFrontEndCarrierIdsForProvider?.length ? (
    acceptsSelectedInsurance ||
    (isInsuranceBCBS &&
      providerHasBCBSCarrier(searchableFrontEndCarrierIdsForProvider)) ? (
      <span>
        Accepts your insurance:{' '}
        <span>
          {frontEndCarrierName}
          {isInsuranceBCBS && isBlueCard ? ' (through BlueCard)' : ''}
        </span>
      </span>
    ) : (
      <span className="flex items-center">
        Insurance:{' '}
        {frontendCarriers ? (
          providerInsurances.join(', ')
        ) : (
          <Skeleton width="12ch" height={theme.fontSize.base} />
        )}
      </span>
    )
  ) : null;

  const specialtiesWithCommas = specialties.flatMap((el, index) => {
    if (index === specialties.length - 1) {
      return [el];
    }

    return [el, ', '];
  });

  // if the provider has focus areas, show their focus area specialties
  // else, if the provider has non-focus area specialties ("other specialties"), show those
  // otherwise, don't show any.
  // for the specialties displayed, make the specialties that match the specialties that
  // the user selected stand out by making them black
  const specialtiesTitle =
    specialties.length === 1 ? 'Specialty' : 'Specialties';

  const tagsWithProperties = isAlgoliaProviderStateRecord(provider)
    ? (provider.styleTags || []).map(getStyleTagProperties)
    : [];

  return (
    <Container
      href={
        isPreview
          ? undefined
          : getProviderProfilePath(provider.slug, {
              preferredCarrierId: frontEndCarrierId,
              isBlueCard: isBlueCard,
              sessionId: sessionId,
              state: searchedState,
              queryId: algoliaQueryId,
            })
      }
      target={isPreview ? undefined : '_blank'}
      css={{
        display: 'block',
        [theme.media.smallDown]: {
          overflow: 'auto hidden',
          maxWidth: '100vw',
        },
      }}
      data-testid="profileLink"
      className={'provider-search-result-card'} // for AB testing metrics
      onClick={onClick}
    >
      <div
        css={{
          borderRadius: 6,
          padding: theme.space.lg,
          paddingBottom: theme.space.lg,
          cursor: isPreview ? 'default' : 'pointer',
          display: 'flex',
          flexDirection: 'column',
          color: theme.color.black,
          background: theme.color.white,
          ':hover': {
            background: theme.color.background,
          },
          [theme.media.medium]: {
            flexDirection: 'row',
          },
          [theme.media.smallDown]: {
            flexDirection: 'row',
            paddingBottom: 0,
          },
        }}
        ref={ref}
      >
        <ImageWithPlaceholder
          src={transformCloudflareImg({ src: provider.photoUrl, width: 160 })}
          css={{
            flexShrink: 0,
            width: 160,
            height: 160,
            borderRadius: 80,
            [theme.media.smallDown]: {
              borderRadius: 38,
              width: 76,
              height: 76,
              marginRight: theme.space.base,
            },
          }}
        />
        <div
          css={{
            flexGrow: 1,
            [theme.media.medium]: {
              marginLeft: theme.space.base,
            },
          }}
        >
          <div>
            <div>
              <div>
                <div
                  css={{
                    color: theme.color.black,
                    marginTop: 0,
                    marginBottom: theme.space.xs,
                    lineHeight: '1em',
                    fontSize: theme.fontSize.xl2,
                    display: 'flex',
                    alignItems: 'flex-start',
                    fontFamily: theme.fontFamily.brandText,
                  }}
                >
                  <div>
                    {provider.prenomial} {provider.displayFirstName}{' '}
                    {provider.displayLastName}
                    {provider.pronouns && (
                      <span css={pronounCss}>
                        {formatProviderPronouns(provider)}
                      </span>
                    )}
                  </div>
                  <Box width={theme.space.base} />
                  <div css={{ display: 'flex' }}>
                    {!isProviderTelehealthOnly(addresses) && (
                      <Tooltip title="Offers in-person sessions">
                        <div
                          css={{
                            backgroundColor: '#F3FCF8',
                            padding: `0 4px 0 4px`,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 4,
                            marginRight: theme.space.xs,
                            width: 32,
                            height: 24,
                          }}
                        >
                          <InPersonIcon width={theme.fontSize.lg} />
                        </div>
                      </Tooltip>
                    )}
                    {providerSupportsTelehealth(provider) && (
                      <Tooltip title="Offers virtual sessions">
                        <div
                          css={{
                            backgroundColor: '#F3FCF8',
                            padding: `0 4px 0 4px`,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 4,
                            marginRight: theme.space.xs,
                            width: 32,
                            height: 24,
                          }}
                        >
                          <VideoChatIcon width={theme.fontSize.base} />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div
                  css={{
                    fontSize: theme.fontSize.sm,
                    color: theme.color.textGray,
                  }}
                >
                  {provider.postnomials
                    ? uniqBy(provider.postnomials, (str: string) =>
                        str.toLowerCase()
                      ).join(', ')
                    : null}
                </div>
                <Divider
                  css={{
                    width: theme.space.xl6,
                    marginTop: theme.space.sm,
                    marginBottom: theme.space.sm,
                    [theme.media.smallDown]: {
                      display: 'none',
                    },
                  }}
                />
              </div>
              <div>
                {insuranceText && (
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      [theme.media.smallDown]: {
                        display: 'none',
                      },
                    }}
                  >
                    <AcceptsInsuranceIcon
                      width={18}
                      css={{ marginRight: theme.space.xs2, flexShrink: 0 }}
                    />
                    <div
                      css={{
                        fontSize: theme.fontSize.sm,
                        color: theme.color.textGray,
                        paddingTop: theme.space.xs2,
                      }}
                      className="line-clamp-2"
                    >
                      {insuranceText}
                    </div>
                  </div>
                )}

                {specialties.length > 0 && (
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      [theme.media.smallDown]: {
                        display: 'none',
                      },
                    }}
                  >
                    <SpecialtiesIcon
                      width={18}
                      height={20}
                      css={{ marginRight: theme.space.xs2, flexShrink: 0 }}
                    />
                    <div
                      css={{
                        fontSize: theme.fontSize.sm,
                        color: theme.color.textGray,
                      }}
                      className="line-clamp-2"
                    >
                      {specialtiesTitle}: {specialtiesWithCommas}
                    </div>
                  </div>
                )}
                {tagsWithProperties.length > 0 ? (
                  <TagsSection
                    tags={tagsWithProperties}
                    css={{
                      [theme.media.smallDown]: {
                        display: 'none',
                      },
                    }}
                  />
                ) : isAlgoliaProviderStateRecord(provider) ? (
                  provider.bioTherapyApproach ? (
                    <BioSection
                      bio={provider.bioTherapyApproach}
                      css={{
                        [theme.media.smallDown]: {
                          display: 'none',
                        },
                      }}
                    />
                  ) : provider.statementHtml ? (
                    <BioSection
                      bio={provider.statementHtml}
                      css={{
                        [theme.media.smallDown]: {
                          display: 'none',
                        },
                      }}
                    />
                  ) : provider.bioAboutYou ? (
                    <BioSection
                      bio={provider.bioAboutYou}
                      css={{
                        [theme.media.smallDown]: {
                          display: 'none',
                        },
                      }}
                    />
                  ) : null
                ) : null}
              </div>
            </div>
          </div>
          {providerAvailabilities && providerAvailabilities.length > 0 && (
            <NextAvailableSection
              providerAvailabilities={providerAvailabilities}
              isMobile={false}
              css={{ [theme.media.smallDown]: { display: 'none' } }}
            />
          )}
        </div>
      </div>
      <div
        css={{
          paddingLeft: theme.space.lg,
          paddingRight: theme.space.lg,
          [theme.media.smallDown]: {
            marginBottom: theme.space.lg,
            marginTop: theme.space.base,
          },
        }}
      >
        {insuranceText && (
          <div
            css={{
              display: 'none',
              alignItems: 'flex-start',
              [theme.media.smallDown]: {
                display: 'flex',
              },
            }}
          >
            <AcceptsInsuranceIcon
              width={18}
              css={{ marginRight: theme.space.xs2, flexShrink: 0 }}
            />
            <div
              className="line-clamp-2"
              css={{
                fontSize: theme.fontSize.sm,
                color: theme.color.textGray,
                paddingTop: theme.space.xs2,
              }}
            >
              {insuranceText}
            </div>
          </div>
        )}
        {specialties.length > 0 && (
          <div
            css={{
              display: 'none',
              alignItems: 'flex-start',
              [theme.media.smallDown]: {
                display: 'flex',
              },
            }}
          >
            <SpecialtiesIcon
              width={18}
              height={20}
              css={{ marginRight: theme.space.xs2, flexShrink: 0 }}
            />
            <div
              className="line-clamp-2"
              css={{
                fontSize: theme.fontSize.sm,
                color: theme.color.textGray,
              }}
            >
              {specialtiesTitle}: {specialtiesWithCommas}
            </div>
          </div>
        )}
        {tagsWithProperties.length > 0 ? (
          <TagsSection
            tags={tagsWithProperties}
            css={{
              display: 'none',
              [theme.media.smallDown]: {
                display: 'flex',
              },
            }}
          />
        ) : isAlgoliaProviderStateRecord(provider) ? (
          provider.bioTherapyApproach ? (
            <BioSection
              bio={provider.bioTherapyApproach}
              css={{
                display: 'none',
                [theme.media.smallDown]: {
                  display: 'flex',
                },
              }}
            />
          ) : provider.statementHtml ? (
            <BioSection
              bio={provider.statementHtml}
              css={{
                display: 'none',
                [theme.media.smallDown]: {
                  display: 'flex',
                },
              }}
            />
          ) : provider.bioAboutYou ? (
            <BioSection
              bio={provider.bioAboutYou}
              css={{
                display: 'none',
                [theme.media.smallDown]: {
                  display: 'flex',
                },
              }}
            />
          ) : null
        ) : null}
        {providerAvailabilities && providerAvailabilities.length > 0 && (
          <NextAvailableSection
            providerAvailabilities={providerAvailabilities}
            isMobile
            css={{
              display: 'none',
              [theme.media.smallDown]: { display: 'flex' },
            }}
          />
        )}
      </div>
    </Container>
  );
};

/* Styles */
const pronounCss = css`
  font-family: ${theme.fontFamily.postGrotesk};
  font-size: ${theme.fontSize.base};
  margin-left: ${theme.space.xs};
`;
const tagNameCss = css`
  color: ${theme.color.black};
  font-family: ${theme.fontFamily.postGrotesk};
  font-size: ${theme.fontSize.sm};
  line-height: ${theme.fontSize.xl2};
  padding-left: ${theme.space.xs};
`;
const tagsContainerCss = css`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${theme.space.xs};
  row-gap: ${theme.space.sm};
  margin-top: ${theme.space.sm};
`;
const tagCss = css`
  background-color: ${theme.color.primaryBackground};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  white-space: nowrap;
`;
const bioCss = css`
  color: ${theme.color.textGray};
  font-size: ${theme.fontSize.sm};
  margin-top: ${theme.space.sm};
`;
const nextAvailabilityStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  color: ${theme.color.black};
`;
