import { mergeProps, useSlotId } from '@react-aria/utils';
import clsx from 'clsx';
import React from 'react';
import { AriaRadioGroupProps, useRadioGroup } from 'react-aria';
import { RadioGroupState, useRadioGroupState } from 'react-stately';
import { Simplify } from 'type-fest';

import { FieldError, FieldRoot } from './fields';
import { Validator } from './forms';
import { theme } from './theme';
import { useFieldValidity } from './useFieldValidity';
import { FormInputProps, useFormInput } from './useFormInput';

export const RadioGroupContext = React.createContext<RadioGroupState | null>(
  null
);

export type RadioGroupProps = Simplify<
  {
    children: React.ReactNode;
    size?: 'small' | 'large';
    optionalityText?: React.ReactNode;
    constraints?: Validator<string | null>[];
  } & FormInputProps<string>
>;

// Generate a random prop name to allow internal Helix components to pass in a className
// modifier without exposing it to the user.
const RadioGroupClassNameModifier = '__HLX__RadioGroup';

export const RadioGroup = ({
  size = 'small',
  // not including RadioGroupClassNameModifier in the props
  // because we don't want to expose it to the user
  // @ts-expect-error
  [RadioGroupClassNameModifier]: _classNameModifier,
  ...props
}: RadioGroupProps) => {
  const rootRef = React.useRef<HTMLDivElement>(null);
  const { ariaProps } = useFormInput(props);

  const { fieldRootProps, ...validationProps } = useFieldValidity({
    ref: rootRef,
    constraints: props.constraints,
    validation: props.validation,
    onCheckValidity: () => {
      if (isInvalid) {
        return false;
      }

      return true;
    },
    focus() {
      // no-op
    },
  });

  const options: AriaRadioGroupProps = mergeProps(ariaProps, validationProps);

  const rgState = useRadioGroupState(options);
  const {
    radioGroupProps,
    labelProps,
    isInvalid,
    validationErrors,
    errorMessageProps,
  } = useRadioGroup(options, rgState);

  const descriptionId = useSlotId([Boolean(props.helpText), props.validation]);

  const optionalityId = useSlotId([Boolean(props.optionalityText)]);

  return (
    <FieldRoot
      ref={rootRef}
      className={clsx('hlx-radio-group-root', _classNameModifier)}
      data-hlx-size={size}
      isDisabled={props.disabled}
      isReadonly={props.readonly}
      isInvalid={isInvalid}
      {...mergeProps(radioGroupProps, fieldRootProps, {
        'aria-describedby':
          [
            descriptionId,
            optionalityId,
            errorMessageProps?.id,
            props['aria-describedby'],
          ]
            .filter(Boolean)
            .join(' ') || undefined,
      })}
    >
      <div className="hlx-radio-group-descriptors">
        {props.label && (
          <span
            className="hlx-radio-group-label"
            style={{
              marginRight: props.optionalityText ? theme.spacing.x2 : 0,
            }}
            {...labelProps}
          >
            {props.label}
          </span>
        )}
        {props.optionalityText && (
          <span id={optionalityId} className="hlx-radio-group-optionality-text">
            {props.optionalityText}
          </span>
        )}
      </div>
      <div className="hlx-radio-group-controls">
        <RadioGroupContext.Provider value={rgState}>
          {props.children}
        </RadioGroupContext.Provider>
      </div>
      {props.helpText && (
        <div className="hlx-radio-group-help-text" id={descriptionId}>
          {props.helpText}
        </div>
      )}
      <FieldError
        className="hlx-radio-group-error"
        isInvalid={isInvalid}
        validationErrors={validationErrors}
        validation={props.validation}
        {...errorMessageProps}
      />
    </FieldRoot>
  );
};

export function RadioCardGroup(props: RadioGroupProps) {
  return (
    <RadioGroup
      {...props}
      {...{
        [RadioGroupClassNameModifier]: 'hlx-radio-card-group',
      }}
    />
  );
}
