import { autocomplete } from '@algolia/autocomplete-js';
import '@algolia/autocomplete-theme-classic';
import { css } from '@emotion/react';
import React, { createElement, Fragment, useEffect, useRef } from 'react';
import { render } from 'react-dom';

import { ProviderStateSearchIndexRecordRead } from '@headway/api/models/ProviderStateSearchIndexRecordRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { SearchIndexApi } from '@headway/api/resources/SearchIndexApi';
import { statesToDisplayNames } from '@headway/shared/constants/unitedStatesDisplayNames';
import { transformCloudflareImg } from '@headway/shared/utils/cloudflareImage';
import { getProviderId } from '@headway/shared/utils/providers';
import { convertListToSentence } from '@headway/shared/utils/strings';
import { ImageWithPlaceholder } from '@headway/ui/providers/ProviderCard';
import { theme } from '@headway/ui/theme';

import {
  SearchResultClickContextSource,
  trackSearchByNameResultClicked,
} from '../../utils/searchAnalyticEvents';

const getSortedStatesForProviderStateRecord = (
  provider: ProviderStateSearchIndexRecordRead
): string[] => {
  return (provider.activeProviderStates || [])
    .filter(
      (activeStateData) =>
        activeStateData.state &&
        activeStateData.shownInSearch &&
        activeStateData.liveOn &&
        new Date(activeStateData.liveOn).getTime() < Date.now()
    )
    .map((activeStateData) => activeStateData.state!)
    .sort();
};

const formatStatesToString = (states: string[]) => {
  const stateDisplayNames = states.map(
    (state) => statesToDisplayNames[state as UnitedStates]
  );
  if (stateDisplayNames.length <= 3) {
    return convertListToSentence(stateDisplayNames);
  }
  return `${stateDisplayNames.slice(0, 3).join(', ')}, +${
    stateDisplayNames.length - 3
  } more`;
};

const ProviderSearchResult = ({
  provider,
}: {
  provider: ProviderStateSearchIndexRecordRead;
}) => {
  const states = getSortedStatesForProviderStateRecord(provider);
  return (
    <a
      href={`/providers/${provider.slug}`}
      onClick={() => {
        trackSearchByNameResultClicked(
          getProviderId(provider),
          SearchResultClickContextSource.SEARCH_NAME_LIST_VIEW
        );
      }}
    >
      <div css={providerSearchResultContainerCss}>
        <ImageWithPlaceholder
          src={transformCloudflareImg({ src: provider.photoUrl, width: 400 })}
          css={providerPhotoCss}
        />
        <div css={{ marginLeft: theme.space.sm }}>
          <div css={{ color: theme.color.black }}>
            {provider.prenomial} {provider.displayFirstName}{' '}
            {provider.displayLastName}
          </div>
          <div css={providerStateCss}>{formatStatesToString(states)}</div>
        </div>
      </div>
    </a>
  );
};

const ProviderSearchByName = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      container: containerRef.current,
      // @ts-ignore algolia "BaseItem" type defs are incorrectly restrictive
      // https://www.algolia.com/doc/ui-libraries/autocomplete/core-concepts/sources/#using-asynchronous-sources
      getSources: ({ query }) => {
        if (query.length < 3) {
          return [
            {
              getItems: () => [],
              sourceId: 'providers',
              templates: {
                noResults: () => (
                  <div css={{ color: theme.color.textGray }}>
                    Results will appear here as you start typing.
                  </div>
                ),
              },
            },
          ];
        }
        return debounced([
          {
            getItems: () =>
              SearchIndexApi.searchProvidersIndex({
                hits_per_page: 10,
                page: 0,
                search_str: query,
                restrict_to_provider_name_search_attributes: true,
              }).then(({ providers }) => providers),
            sourceId: 'providers',
            templates: {
              item: ({
                item,
              }: {
                item: ProviderStateSearchIndexRecordRead;
              }) => <ProviderSearchResult provider={item} />,
              noResults: () => (
                <div css={{ color: theme.color.textGray }}>
                  No results found. Check your spelling and try again.
                </div>
              ),
            },
          },
        ]);
      },
      openOnFocus: true,
      placeholder: 'Search by first or last name',
      // @ts-ignore algolia type defs are incorrect for render, we follow the docs:
      // https://www.algolia.com/doc/ui-libraries/autocomplete/integrations/using-react/#with-react-168-or-17
      renderer: { createElement, Fragment, render },
    });

    return () => {
      search.destroy();
    };
  }, []);

  return (
    <div css={searchContainerCss}>
      <div ref={containerRef} />
    </div>
  );
};

const providerPhotoCss = css`
  border-radius: 80px;
  height: 48px;
  width: 48px;
`;
const providerSearchResultContainerCss = css`
  align-items: center;
  display: flex;
  padding: ${theme.space.xs2};
`;
const providerStateCss = css`
  color: ${theme.color.textGray};
  font-size: ${theme.fontSize.sm};
  margin-top: ${theme.space.xs};
`;

const searchContainerCss = css`
  .legacy-global-css .aa-SubmitButton {
    padding-top: ${theme.space.xs};
  }
`;

// From https://www.algolia.com/doc/ui-libraries/autocomplete/guides/debouncing-sources/
const debouncePromise = (fn: any, time: number) => {
  let timerId: ReturnType<typeof setTimeout> | undefined = undefined;
  const debounced = (...args: any) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    return new Promise((resolve) => {
      timerId = setTimeout(() => resolve(fn(...args)), time);
    });
  };
  return debounced;
};
const debounced = debouncePromise((items: any) => Promise.resolve(items), 250);

export { ProviderSearchByName };
